// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chaotic-maps-js": () => import("./../../../src/pages/chaoticMaps.js" /* webpackChunkName: "component---src-pages-chaotic-maps-js" */),
  "component---src-pages-hausdorff-dimensions-js": () => import("./../../../src/pages/hausdorffDimensions.js" /* webpackChunkName: "component---src-pages-hausdorff-dimensions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lindenmayer-systems-js": () => import("./../../../src/pages/lindenmayerSystems.js" /* webpackChunkName: "component---src-pages-lindenmayer-systems-js" */),
  "component---src-pages-strange-attractors-js": () => import("./../../../src/pages/strangeAttractors.js" /* webpackChunkName: "component---src-pages-strange-attractors-js" */)
}

